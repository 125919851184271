import { createAction } from '@reduxjs/toolkit';
import type { StateKey } from '@store/state.types';

export const CLEAR_CURRENT_STATE = 'clearCurrentState';
export const CLEAR_CURRENT = 'clearCurrent';
export const CLEAR_UPCOMING = 'clearUpcoming';
export const ABORT_REQUEST = 'abortRequest';

export const clearCurrentAction = createAction<StateKey>(CLEAR_CURRENT);
export const clearCurrentState = createAction<string | undefined>(CLEAR_CURRENT_STATE);
export const clearUpcoming = createAction(CLEAR_UPCOMING);
export const abortRequest = createAction(ABORT_REQUEST);
