import {
    type ActionCreatorWithPayload,
    type ActionCreatorWithoutPayload,
    type ActionReducerMapBuilder,
    type PayloadAction,
    type UnknownAction,
    type createAsyncThunk,
} from '@reduxjs/toolkit';
import { abortRequest } from '@shared/http/http.service.ts';
import {
    type BaseState,
    type StateKey,
    type StateList,
    type StateListAndCurrent,
    type StateListBulk,
    type StateListWoPagination,
    type StateSingle,
} from '@store/state.types';
import { paginationMetaInitial } from '../content-wrapper/pagination/pagination-meta-initial';
import { type Pagination } from '../content-wrapper/pagination/pagination.types';
import { CLEAR_CURRENT, CLEAR_CURRENT_STATE } from './clear.actions';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import type { AppState } from '@store/store';

const commonInitialState: BaseState = {
    pending: false,
    isInitialized: false,
    formPending: false,
};

// TODO(xakeppok) - reduce the count of initial states - double check
export const getInitialStateList = <List>(): StateList<List> => ({
    ...commonInitialState,
    items: [] as List[],
    meta: paginationMetaInitial,
    type: 'list',
    query: {},
});

export const getInitialStateListWoPagination = <List>(): StateListWoPagination<List> => ({
    ...commonInitialState,
    items: [] as List[],
    type: 'listWoPagination',
    query: {},
});

export const getInitialStateListAndCurrent = <List, Current, Insights = any>(): StateListAndCurrent<
    List,
    Current,
    Insights
> => ({
    ...getInitialStateList<List>(),
    current: null as Current | null,
    insights: null as Insights | null,
    insightsPending: false,
    getCurrentPending: 0,
    type: 'listAndCurrent',
    query: {},
});

export const getInitialStateListBulk = <List>(): StateListBulk<List> => ({
    ...commonInitialState,
    items: [] as List[],
    meta: paginationMetaInitial,
    type: 'listBulk',
    query: {},
});

export const getInitialStateSingle = <Current>(): StateSingle<Current> => ({
    ...commonInitialState,
    current: null as Current | null,
    type: 'single',
});

const isStateList = <T>(state: States<T, any>): state is StateList<T> => state.type === 'list';

const isStateListBulk = <T>(state: States<T, any>): state is StateListBulk<T> => state.type === 'listBulk';

const isStateListAndCurrent = <List, Current>(
    state: States<Current, List>,
): state is StateListAndCurrent<List, Current> => state.type === 'listAndCurrent';

const isStateSingle = <T>(state: States<T, any>): state is StateSingle<T> => state.type === 'single';

const isStateListWoPagination = <List>(state: States<any, List>): state is StateListWoPagination<List> =>
    state.type === 'listWoPagination';

const isInsightsAction = <Current, List>(
    state: States<Current, List>,
    action: UnknownAction,
): state is StateListAndCurrent<List, Current> => action.type.includes('get-insights') && isStateListAndCurrent(state);

export const handleClearCurrent = (action: UnknownAction, state: AppState): AppState => {
    if (action.type === CLEAR_CURRENT) {
        const payload = action?.['payload'] as StateKey | undefined;
        if (payload) {
            const clonedState = cloneDeep(state);
            const partState = get(clonedState, payload);
            if (isStateListAndCurrent(partState) || isStateSingle(partState)) {
                partState.current = null;
            }

            return clonedState;
        }
        return state;
    }
    return state;
};

type States<Current, List> =
    | StateList<List>
    | StateSingle<Current>
    | StateListAndCurrent<List, Current>
    | StateListBulk<List>
    | StateListWoPagination<List>;
// TODO(xakeppok): split by list, single, list and current, list bulk and list wo pagination
export const createAsyncReducer = <Current, List, State extends States<Current, List>>(
    thunks: ReturnType<typeof createAsyncThunk<any, any, { rejectValue: UnknownAction | null }>>[],
    regularActions?: (ActionCreatorWithPayload<string | undefined> | ActionCreatorWithoutPayload)[],
    customCases?: (builder: ActionReducerMapBuilder<State>) => void,
) => {
    return (builder: ActionReducerMapBuilder<State>): void => {
        regularActions?.forEach((action) => {
            builder.addCase(action, <Current, List>(state: States<Current, List>, action: UnknownAction): any => {
                if (action.type === CLEAR_CURRENT_STATE) {
                    // TODO(xakeppok): does it make sense to make the same as handleClearCurrent
                    abortRequest();
                    if (isStateList(state)) {
                        return { ...state, ...getInitialStateList<List>() };
                    }
                    if (isStateListAndCurrent(state)) {
                        const currentPath = window.location.pathname;
                        const previousPath = action?.['payload'] as string | undefined;
                        let slugWasAdded = false;
                        if (previousPath) {
                            const currentPathSegments = currentPath.split('/').filter(Boolean);
                            const previousPathSegments = previousPath.split('/').filter(Boolean);
                            if (currentPathSegments.length === previousPathSegments.length + 1) {
                                slugWasAdded = previousPathSegments.every(
                                    (segment, index) => segment === currentPathSegments[index],
                                );
                            }
                        }
                        const newState = { ...state, ...getInitialStateListAndCurrent<List, Current>() };
                        if (slugWasAdded) {
                            newState.items = state.items;
                        }
                        return newState;
                    }
                    if (isStateListBulk(state)) {
                        return { ...state, ...getInitialStateListBulk<List>() };
                    }
                    if (isStateListWoPagination(state)) {
                        return { ...state, ...getInitialStateListWoPagination<List>() };
                    }
                    if (isStateSingle(state)) {
                        return { ...state, ...getInitialStateSingle<Current>() };
                    }
                }
            });
        });
        thunks.forEach((action) => {
            builder
                .addCase(action.pending, <Current, List>(state: States<Current, List>, action: UnknownAction): void => {
                    if (isInsightsAction(state, action)) {
                        state.insightsPending = true;
                    } else if (
                        action.type.includes('get') &&
                        (!action.type.includes('get-one') || isStateSingle(state))
                    ) {
                        state.pending = true;
                    }
                    if (action.type.includes('action')) {
                        state.formPending = true;
                    }
                    if (action.type.includes('get-one') && isStateListAndCurrent(state)) {
                        state.getCurrentPending++;
                    }
                })
                .addCase(
                    action.fulfilled,
                    <Current extends object, List>(
                        state: States<Current, List>,
                        action: PayloadAction<Pagination<List> | Current | List[]>,
                    ): void => {
                        const isActionPagination = (
                            payload?: Pagination<List> | Current | List[],
                        ): payload is Pagination<List> => !!payload && 'items' in payload;

                        const isPayloadArray = (payload?: Pagination<List> | Current | List[]): payload is List[] =>
                            Array.isArray(payload);

                        if (isInsightsAction(state, action)) {
                            state.insights = action.payload;
                            state.insightsPending = false;
                        }
                        if ('query' in state && (action.type.includes('get-all') || isInsightsAction(state, action))) {
                            // TODO(xakeppok): add types for query
                            state.query = (action as any).meta.arg;
                        }

                        if (isActionPagination(action.payload)) {
                            if (isStateList(state) || isStateListAndCurrent(state) || isStateListBulk(state)) {
                                state.items = action.payload.items;
                                state.meta = action.payload.meta;
                                state.pending = false;
                                state.isInitialized = true;
                            }
                        } else if (isPayloadArray(action.payload)) {
                            if (isStateListWoPagination(state)) {
                                state.items = action.payload;
                                state.pending = false;
                                state.isInitialized = true;
                            }
                        } else {
                            if (action.type.includes('action')) {
                                state.formPending = false;
                            }
                            if (isStateListAndCurrent(state) || isStateSingle(state)) {
                                if (action.type.includes('get-one')) {
                                    state.current = action.payload;
                                    if (isStateListAndCurrent(state)) {
                                        state.getCurrentPending--;
                                    }
                                    if (isStateSingle(state)) {
                                        state.pending = false;
                                        state.isInitialized = true;
                                    }
                                }
                            }
                        }
                    },
                )
                .addCase(
                    action.rejected,
                    <Current, List>(state: States<Current, List>, action: UnknownAction): void => {
                        if (isInsightsAction(state, action)) {
                            state.insightsPending = false;
                        }
                        if (action.type.includes('action')) {
                            state.formPending = false;
                        }
                        if (action.type.includes('all') || (action.type.includes('get-one') && isStateSingle(state))) {
                            state.pending = false;
                            state.isInitialized = true;
                        }
                        if (isStateListAndCurrent(state) && action.type.includes('get-one')) {
                            state.getCurrentPending--;
                        }
                    },
                );
        });
        customCases?.(builder);
    };
};
