import { SingleApiService } from '@pages/dashboard/shared/crud/single/single-api.service';
import { type HolidaysConfiguration } from './holidays-configuration.types';

export class SettingsHolidaysConfigurationService extends SingleApiService<
    HolidaysConfiguration,
    HolidaysConfiguration
> {
    public constructor() {
        super('holidays-configuration');
    }
}
const settingsHolidaysConfigurationService = new SettingsHolidaysConfigurationService();
export default settingsHolidaysConfigurationService;
