import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListAndCurrent } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateListAndCurrent } from '@store/state.types.ts';
import { setStateValue } from '@shared/store/store.utils';
import { EmployeesThunks } from './employees.thunk';
import { type Employee, type EmployeeInsights, type EmployeeList } from './employees.types';

export type EmployeesState = StateListAndCurrent<EmployeeList, Employee, EmployeeInsights> & {
    changePhotoPending: boolean;
};

export const employeesThunks = new EmployeesThunks();
const { stateKey, changePhotoThunk, recoverThunk, archiveThunk, defaultThunks } = employeesThunks;

const initialState = {
    ...getInitialStateListAndCurrent<EmployeeList, Employee, EmployeeInsights>(),
    changePhotoPending: false,
};

export const employeesSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(
        [...defaultThunks, recoverThunk, archiveThunk],
        [clearCurrentState],
        (builder) => {
            builder.addCase(changePhotoThunk.fulfilled, setStateValue('changePhotoPending', false));
            builder.addCase(changePhotoThunk.rejected, setStateValue('changePhotoPending', false));
            builder.addCase(changePhotoThunk.pending, setStateValue('changePhotoPending', true));
        },
    ),
});

export default employeesSlice.reducer;
