import { SingleThunks } from '@pages/dashboard/shared/crud/single/single-thunks';
import { updateSingleThunk } from '@pages/dashboard/shared/crud/update.thunks';
import { type AppState } from '@store/store';
import { holidaysThunks } from '../../holidays/store/holidays.slice';
import { type HolidaysFilterQuery } from '../../holidays/store/holidays.types';
import type { SettingsHolidaysConfigurationService } from './holidays-configuration.service';
import settingsHolidaysConfigurationService from './holidays-configuration.service';
import { type HolidaysConfiguration } from './holidays-configuration.types';

export class SettingsHolidaysConfigurationThunks extends SingleThunks<
    SettingsHolidaysConfigurationService,
    HolidaysConfiguration,
    HolidaysConfiguration
> {
    public constructor() {
        super(settingsHolidaysConfigurationService, 'settings.holidaysConfiguration');

        this.updateThunk = updateSingleThunk<Partial<HolidaysConfiguration>>({
            stateKey: this.stateKey,
            successMessage: `${this.successMessagePrefix}.UPDATED`,
            serviceFunction: this.service.update.bind(this.service),
            thunksOnSuccess: this.getThunksOnSuccess.bind(this),
        });
    }

    private getThunksOnSuccess(state: AppState) {
        return [this.getOneThunk(), holidaysThunks.getAllThunk(state.holidays.query as HolidaysFilterQuery)];
    }
}
