import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { SettingsGeneralThunks } from './general-settings.thunk';
import type { GeneralSettings } from './general-settings.types';

export type SettingsGeneralState = StateSingle<GeneralSettings>;

export const settingsGeneralThunks = new SettingsGeneralThunks();

const { stateKey, defaultThunks } = settingsGeneralThunks;

const initialState: SettingsGeneralState = { ...getInitialStateSingle() };

export const generalSettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState]),
});

export default generalSettingsSlice.reducer;
