import { createSlice } from '@reduxjs/toolkit';
import type { FcmPayload } from './fcm.types';

export type FcmState = {
    fcmToken: string | null;
    data: FcmPayload | null;
    initialData: FcmPayload | null;
};

const initialState: FcmState = {
    fcmToken: null,
    initialData: null,
    data: null,
};

const stateKey = 'fcm';

export const fcmSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {
        setFcmToken: (state, { payload }: { payload: string }) => {
            state.fcmToken = payload;
        },
        setFcmData: (state, { payload }: { payload: FcmPayload }) => {
            state.data = payload;
        },
        setInitialFcmData: (state, { payload }: { payload: FcmPayload }) => {
            state.initialData = payload;
        },
        clearFcmData: (state) => {
            state.data = null;
        },
        clearInitialFcmData: (state) => {
            state.initialData = null;
        },
    },
});

export const { setFcmToken, setFcmData, setInitialFcmData, clearFcmData, clearInitialFcmData } = fcmSlice.actions;
export default fcmSlice.reducer;
