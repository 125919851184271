import { type Language } from '@shared-lib/language.enum.ts';
import { authHttpClient } from '@shared/http/http.service.ts';
import { LocalStorageKey } from '@shared/local-storage/local-storage.hook.ts';
import { changeLanguage as changeLang } from 'i18next';
import {
    type AuthResponse,
    type ForgotPassword,
    type Login,
    type ResetPassword,
    type SetPassword,
} from './auth.types.ts';

const PREFIX = 'auth';

export const login = async (login: Login): Promise<AuthResponse> => {
    const user: AuthResponse = await authHttpClient.post(`${PREFIX}/login`, login);
    await setLanguage(user.language);
    return user;
};

export const checkToken = async (): Promise<AuthResponse> => {
    const user: AuthResponse = await authHttpClient.get(`${PREFIX}/`);
    await setLanguage(user.language);
    return user;
};

export const forgotPassword = (forgotPassword: ForgotPassword): Promise<void> => {
    return authHttpClient.post(`${PREFIX}/forgot-password`, forgotPassword);
};

export const resetPassword = (resetPassword: ResetPassword): Promise<void> => {
    return authHttpClient.patch(`${PREFIX}/reset-password`, resetPassword);
};

export const setPassword = (setPassword: SetPassword): Promise<void> => {
    const path = setPassword.token ? 'set-password' : 'set-auth-password';
    return authHttpClient.patch(`${PREFIX}/${path}`, setPassword);
};

export const changeLanguage = async (language: Language, isAuthenticated = true): Promise<void> => {
    if (isAuthenticated) {
        await authHttpClient.patch(`${PREFIX}/language`, { language, isMobile: false });
    }
    await setLanguage(language);
};

export const logout = async (fcmToken: string | null): Promise<void> => {
    authHttpClient.post(`${PREFIX}/logout`, { fcmToken });
};

const setLanguage = async (language: Language): Promise<void> => {
    await changeLang(language);
    localStorage.setItem(LocalStorageKey.LANGUAGE, language);
};
