import { createSlice } from '@reduxjs/toolkit';
import { type StateList } from '@store/state.types.ts';
import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateList } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { NotificationsList } from '@pages/dashboard/pages/notifications/store/notifications.types.ts';
import { getNotificationsThunk } from './notifications.thunk.ts';

export type NotificationsState = StateList<NotificationsList>;

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: getInitialStateList<NotificationsList>(),
    reducers: {},
    extraReducers: createAsyncReducer([getNotificationsThunk], [clearCurrentState]),
});

export default notificationsSlice.reducer;
