import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { SettingsDatabaseBackupThunks } from './database-backup.thunk';
import { type DatabaseBackup } from './database-backup.types';

export type SettingsDatabaseBackupState = StateSingle<DatabaseBackup>;

export const settingsDatabaseBackupThunks = new SettingsDatabaseBackupThunks();

const { stateKey, defaultThunks } = settingsDatabaseBackupThunks;

const initialState: SettingsDatabaseBackupState = getInitialStateSingle();

export const databaseBackupSettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState]),
});

export default databaseBackupSettingsSlice.reducer;
