import { createSlice, isFulfilled } from '@reduxjs/toolkit';
import { type InitialBaseState } from '@store/state.types.ts';
import { checkTokenThunk, loginThunk } from '@auth/store/auth.thunk';
import { ActivityType } from '@shared-lib/activity/activity.enums';
import { type EntityType, attachmentEntityTypes } from '@shared-lib/entity-type/entity-type.enum.ts';
import { type EmployeeLookup, type Lookup, type LookupName } from './lookup.types.ts';
import { getLookupsThunk } from './lookups.thunk';
import { type TagType } from '@shared-lib/tags/tag-type.enum.ts';
import { countries } from '@shared-lib/constants/constants.ts';
import { type Country } from '@shared-lib/types/country.type.ts';

type LookupStateItem<T = Lookup> = InitialBaseState & {
    items: T[];
};

type LookupTagTypes = Record<(typeof TagType)[keyof typeof TagType], LookupStateItem>;

export type LookupsState = LookupTagTypes & {
    links: LookupStateItem;
    positions: LookupStateItem;
    countries: string[];
    employees: LookupStateItem<EmployeeLookup>;
    clients: LookupStateItem;
    projects: LookupStateItem;
    types: string[];
    entityTypes: EntityType[];
};

const initialState: LookupsState = {
    types: Object.values(ActivityType).filter((type) => type !== ActivityType.UNKNOWN),
    countries: countries.map((country: Country) => country.countryCode),
    entityTypes: [],
    links: { items: [], pending: false },
    stack: { items: [], pending: false },
    employeeEnglishLevels: { items: [], pending: false },
    industries: { items: [], pending: false },
    clients: { items: [], pending: false },
    inventoryTags: { items: [], pending: false },
    documentTags: { items: [], pending: false },
    positions: { items: [], pending: false },
    employees: { items: [], pending: false },
    projects: { items: [], pending: false },
};

const stateKey = 'lookups';

const isCheckTokenOrLoginAction = isFulfilled(checkTokenThunk, loginThunk);

export const lookupsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                getLookupsThunk.pending,
                (
                    state,
                    {
                        meta: {
                            arg: { lookupName, needSilentFetch },
                        },
                    },
                ) => {
                    if (!needSilentFetch) {
                        state[lookupName].pending = true;
                    }
                },
            )
            .addCase(
                getLookupsThunk.fulfilled,
                (
                    state,
                    {
                        payload,
                        meta: {
                            arg: { lookupName },
                        },
                    },
                ) => {
                    state[lookupName].pending = false;
                    state[lookupName].items = payload;
                },
            )
            .addCase(
                getLookupsThunk.rejected,
                (
                    state,
                    {
                        meta: {
                            arg: { lookupName },
                        },
                    },
                ) => {
                    // TODO(xakeppok): test without if
                    if (lookupName) {
                        state[lookupName].pending = false;
                    }
                },
            )
            .addMatcher(isCheckTokenOrLoginAction, (state, { payload: { employee } }) => {
                // TODO(xakeppok): move allowed entityTypes to backend?
                state.entityTypes =
                    employee?.permissions
                        .filter(({ entityType, view }) => !attachmentEntityTypes.includes(entityType) && view)
                        .map(({ entityType }) => entityType) ?? [];
            });
    },
});

export const selectLookups = (state: LookupsState, stateKey: LookupName): string[] =>
    state[stateKey].items.map(({ value }) => value);

export default lookupsSlice.reducer;
