import { type Language } from '@shared-lib/language.enum.ts';
import { createAsyncThunkWrapper, handleAxiosError } from '@shared/store/store.utils.ts';
import { showToast } from '@shared/toast/store/toast.thunk.ts';
import { AppState } from './../../../store/store';
import * as authService from './auth.service';
import {
    type AuthResponse,
    type ForgotPassword,
    type Login,
    type ResetPassword,
    type SetPassword,
} from './auth.types.ts';

export const authLoginType = 'auth/login';
export const authLogoutType = 'auth/logout';
export const authForgotPasswordType = 'auth/forgotPassword';
export const authResetPasswordType = 'auth/resetPassword';
export const authCheckTokenType = 'auth/checkToken';
export const authChangeLanguageType = 'auth/language';
export const authSetPasswordType = 'auth/set-password';

export const loginThunk = createAsyncThunkWrapper<AuthResponse, Login>({
    actionType: authLoginType,
    serviceFunction: (params: Login, _, { fcm: { fcmToken } }: AppState) =>
        authService.login({
            ...params,
            fcmToken,
        }),
});

export const checkTokenThunk = createAsyncThunkWrapper<AuthResponse, void>({
    actionType: authCheckTokenType,
    serviceFunction: authService.checkToken,
    errorCallback: (error, dispatch) => {
        if (error?.response?.status !== 401) {
            return handleAxiosError(error, dispatch);
        }
    },
});

export const changeLanguageThunk = createAsyncThunkWrapper<void, Language>({
    actionType: authChangeLanguageType,
    serviceFunction: (language, _, state) => authService.changeLanguage(language, state.auth.isAuthenticated),
});

export const setPasswordThunk = createAsyncThunkWrapper<void, SetPassword>({
    actionType: authSetPasswordType,
    serviceFunction: (setPassword) => authService.setPassword(setPassword),
});

export const logoutThunk = createAsyncThunkWrapper<void, void>({
    actionType: authLogoutType,
    serviceFunction: (_, __, { fcm: { fcmToken } }: AppState) => authService.logout(fcmToken),
});

export const forgotPasswordThunk = createAsyncThunkWrapper<void, ForgotPassword>({
    actionType: authForgotPasswordType,
    serviceFunction: async (forgotPassword, { dispatch }) => {
        await authService.forgotPassword(forgotPassword);
        dispatch(showToast({ type: 'success', message: 'AUTH.RESET_PASSWORD_EMAIL_SENT' }));
    },
});

export const resetPasswordThunk = createAsyncThunkWrapper<void, ResetPassword>({
    actionType: authResetPasswordType,
    serviceFunction: async (resetPassword, { dispatch }) => {
        await authService.resetPassword(resetPassword);
        dispatch(checkTokenThunk());
    },
});
