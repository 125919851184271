import { abortRequest } from '@shared/http/http.service.ts';
import { LocalStorageKey, useLocalStorage } from '@shared/local-storage/local-storage.hook.ts';
import { Theme } from '@shared/theme-switcher/theme.enum.ts';
import Toasts from '@shared/toast/toast.tsx';
import { type ReactElement, useEffect, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppInitializer from './app-initializer/app-initializer.tsx';
import { getRoutesByConfiguration } from './router/router.tsx';
import { routesConfiguration } from './routes.tsx';
import { store } from './store/store.ts';
import './styles.scss';

const App = (): ReactElement | null => {
    const html = useRef(document.querySelector('html'));
    const [theme] = useLocalStorage(LocalStorageKey.THEME, { defaultValue: 'light' });
    const router = createBrowserRouter(getRoutesByConfiguration(routesConfiguration));
    useEffect(() => {
        const systemTheme =
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? Theme.DARK : Theme.LIGHT;
        html.current?.setAttribute('data-theme', theme ?? systemTheme);
        document.documentElement.classList.add('data-theme', theme ?? systemTheme);
    }, [theme]);

    router.subscribe(({ historyAction }) => {
        if (historyAction === 'PUSH') {
            abortRequest();
        }
    });

    return (
        <Provider store={store}>
            <AppInitializer>
                <RouterProvider router={router} />
                <Toasts />
            </AppInitializer>
        </Provider>
    );
};

export default App;
