import { type InventoryList, type InventoryRecord } from '@inventory/store/inventory.types.ts';
import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions.ts';
import { createAsyncReducer, getInitialStateListAndCurrent } from '@pages/dashboard/shared/crud/crud.reducers.ts';
import { createSlice } from '@reduxjs/toolkit';
import { type StateListAndCurrent } from '@store/state.types.ts';
import { InventoryThunks } from './inventory.thunk';

export type InventoryState = StateListAndCurrent<InventoryList, InventoryRecord>;

export const inventoryThunks = new InventoryThunks();
const { forceCreateThunk, stateKey, defaultThunks } = inventoryThunks;

const initialState = getInitialStateListAndCurrent<InventoryList, InventoryRecord>();

export const inventorySlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState], (builder) => {
        builder.addCase(forceCreateThunk.fulfilled, (state) => {
            state.getCurrentPending--;
        });
        builder.addCase(forceCreateThunk.rejected, (state) => {
            state.getCurrentPending--;
        });
        builder.addCase(forceCreateThunk.pending, (state) => {
            state.getCurrentPending++;
        });
    }),
});

export default inventorySlice.reducer;
