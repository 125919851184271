import httpClient from '@shared/http/http.service.ts';
import { type Pagination } from '@pages/dashboard/shared/content-wrapper/pagination/pagination.types.ts';
import {
    NotificationsList,
    NotificationsListQuery,
} from '@pages/dashboard/pages/notifications/store/notifications.types.ts';

const PREFIX = 'notifications';

export const getNotifications = (params?: NotificationsListQuery): Promise<Pagination<NotificationsList>> => {
    return httpClient.get(PREFIX, { params });
};
