import { useAppDispatch } from '@store/store.hooks';
import { getToken } from 'firebase/messaging';
import { setFcmToken } from '../../app-initializer/fcm/store/fcm.slice';
import { messaging } from '../../firebase-config';

export const useNotificationPermission = (): (() => Promise<void>) => {
    const dispatch = useAppDispatch();
    const requestPermission = async () => {
        const permission = await Notification.requestPermission();

        if (permission === 'granted') {
            const token = await getToken(messaging, {
                // It's public can, so can be on the version
                vapidKey: 'BOUJ3FOSHzyLGCYLjZxpwWTi90OMMNNs5S_0ABiu5osouMgEys8tJNYB_11lS0LyEOVCJ-Q6mp3ab10fzPWpeFA',
            });
            dispatch(setFcmToken(token));
        }
    };
    return requestPermission;
};
