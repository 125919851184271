import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { SettingsHolidaysScheduleThunks } from './holidays-schedule.thunk';
import { type HolidaysSchedule } from './holidays-schedule.types';

export type SettingsHolidaysScheduleState = StateSingle<HolidaysSchedule>;

// TODO(xakeppok): rename to settings?
export const settingsHolidaysScheduleThunks = new SettingsHolidaysScheduleThunks();

const { stateKey, defaultThunks } = settingsHolidaysScheduleThunks;

const initialState: SettingsHolidaysScheduleState = getInitialStateSingle<HolidaysSchedule>();

export const holidaysScheduleSettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState]),
});

export default holidaysScheduleSettingsSlice.reducer;
