import { Language } from '@shared-lib/language.enum';
import { TranslationsNameSpaces } from '@shared/translation.enum.ts';
import enNotifications from '@pages/dashboard/pages/notifications/i18n/en.json';
import ukNotifications from '@pages/dashboard/pages/notifications/i18n/uk.json';
import ruNotifications from '@pages/dashboard/pages/notifications/i18n/ru.json';
import ruNotificationDetails from '@pages/dashboard/pages/notifications/notification-details/i18n/ru.json';
import ukNotificationDetails from '@pages/dashboard/pages/notifications/notification-details/i18n/uk.json';
import enNotificationDetails from '@pages/dashboard/pages/notifications/notification-details/i18n/en.json';
import enNotificationListItem from '@pages/dashboard/pages/notifications/notification-list-item/i18n/en.json';
import ukNotificationListItem from '@pages/dashboard/pages/notifications/notification-list-item/i18n/uk.json';
import ruNotificationListItem from '@pages/dashboard/pages/notifications/notification-list-item/i18n/ru.json';

export const notificationsI18n = {
    [Language.EN]: {
        [TranslationsNameSpaces.NOTIFICATIONS]: enNotifications,
        [TranslationsNameSpaces.NOTIFICATION_DETAILS]: enNotificationDetails,
        [TranslationsNameSpaces.NOTIFICATION_LIST_ITEM]: enNotificationListItem,
    },
    [Language.UK]: {
        [TranslationsNameSpaces.NOTIFICATIONS]: ukNotifications,
        [TranslationsNameSpaces.NOTIFICATION_DETAILS]: ukNotificationDetails,
        [TranslationsNameSpaces.NOTIFICATION_LIST_ITEM]: ukNotificationListItem,
    },
    [Language.RU]: {
        [TranslationsNameSpaces.NOTIFICATIONS]: ruNotifications,
        [TranslationsNameSpaces.NOTIFICATION_DETAILS]: ruNotificationDetails,
        [TranslationsNameSpaces.NOTIFICATION_LIST_ITEM]: ruNotificationListItem,
    },
};
