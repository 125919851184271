import { useNotificationPermission } from '@shared/notification-permission/notification-permission.hook';
import { useAppDispatch, useAppSelector } from '@store/store.hooks';
import { onMessage, type MessagePayload } from 'firebase/messaging';
import { useEffect, useRef } from 'react';
import { messaging } from '../../firebase-config';
import { setFcmData, setInitialFcmData } from './store/fcm.slice';
import type { FcmPayload } from './store/fcm.types';
import type { UnknownAction } from '@reduxjs/toolkit';

export const useFcmInitializer = () => {
    const requestPermission = useNotificationPermission();
    const dispatch = useAppDispatch();

    const userId = useAppSelector((state) => state.auth.userId);
    const userIdRef = useRef(userId);
    useEffect(() => {
        userIdRef.current = userId;
    }, [userId]);

    // TODO(xakeppok): any better approach?
    const parseJSONFields = (obj: any) => {
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        Object.keys(obj).forEach((key) => {
            try {
                obj[key] = JSON.parse(obj[key]);
            } catch {
                /* empty */
            }
        });

        return obj;
    };

    useEffect(() => {
        requestPermission();
        onMessage(messaging, (payload) => {
            setData(payload, setFcmData);
        });
    }, []);

    const setData = (payload: MessagePayload, cb: (data: FcmPayload) => UnknownAction) => {
        const data: FcmPayload = {
            ...parseJSONFields(payload.data),
            ...payload.notification,
        };
        dispatch(cb(data));
    };

    useEffect(() => {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
                if (registration.active) {
                    registration.active.postMessage({ type: 'app_ready' });
                }
            });
        });
        const messageHandler = (event: MessageEvent) => {
            const result = event.data;

            const payload = result.payload;
            if (result?.type === 'notification_click') {
                setData(payload, setInitialFcmData);
            }
        };

        navigator.serviceWorker.addEventListener('message', messageHandler);

        return () => {
            navigator.serviceWorker.removeEventListener('message', messageHandler);
        };
    }, []);
};
