import * as notificationsService from './notifications.service.ts';
import {
    NotificationsList,
    NotificationsListQuery,
} from '@pages/dashboard/pages/notifications/store/notifications.types.ts';
import { getListThunk } from '@dashboard-shared/crud/get.thunks.ts';
import { Pagination } from '@dashboard-shared/content-wrapper/pagination/pagination.types.ts';

export const getNotificationsThunk = getListThunk<Pagination<NotificationsList>, NotificationsListQuery>({
    serviceFunction: notificationsService.getNotifications,
    stateKey: 'notifications',
});
