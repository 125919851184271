import { clearCurrentState } from '@pages/dashboard/shared/crud/clear.actions';
import { createAsyncReducer, getInitialStateSingle } from '@pages/dashboard/shared/crud/crud.reducers';
import { createSlice } from '@reduxjs/toolkit';
import { type StateSingle } from '@store/state.types.ts';
import { SettingsHolidaysConfigurationThunks } from './holidays-configuration.thunk';
import { type HolidaysConfiguration } from './holidays-configuration.types';

export type SettingsHolidaysConfigurationState = StateSingle<HolidaysConfiguration>;

// TODO(xakeppok): rename to settings?
export const settingsHolidaysConfigurationThunks = new SettingsHolidaysConfigurationThunks();

const { stateKey, defaultThunks } = settingsHolidaysConfigurationThunks;

const initialState: SettingsHolidaysConfigurationState = getInitialStateSingle<HolidaysConfiguration>();

export const holidaysConfigurationSettingsSlice = createSlice({
    name: stateKey,
    initialState,
    reducers: {},
    extraReducers: createAsyncReducer(defaultThunks, [clearCurrentState]),
});

export default holidaysConfigurationSettingsSlice.reducer;
