import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const app = initializeApp({
    apiKey: 'AIzaSyA77zx5ESUI8Nb7pISfx_Q4mDCBRYHNHro',
    authDomain: 'company-id-system-mobile.firebaseapp.com',
    projectId: 'company-id-system-mobile',
    storageBucket: 'company-id-system-mobile.firebasestorage.app',
    messagingSenderId: '61210961939',
    appId: '1:61210961939:web:deeeb4d13477cbb5f6f7e6',
    measurementId: 'G-G2CPDMERB2',
});
const messaging = getMessaging(app);

export { messaging };
